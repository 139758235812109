import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import CardCoupon from '../../components/coupon/card/CardCoupon';
import axios from 'axios';
import './Coupons.scss';
import AddCardCoupon from '../../components/coupon/addcard/AddCardCoupon';

export default function Coupons() {

  const logged = localStorage.getItem('logged');
  const adminLogged = localStorage.getItem('adminLogged');
  const location = useLocation();

  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchCoupons = () => {
    let api_url = process.env.REACT_APP_API_ADDRESS;
    if (api_url.endsWith("/")) {
        api_url = api_url.slice(0, -1); // Assurez-vous que l'URL est correcte
    }
    setLoading(true);
    axios.get(`${api_url}/api/coupons`, {
      withCredentials: true, // Important si votre API utilise des cookies/session pour l'authentification
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD
      }
    })
    .then(response => {
      setCoupons(response.data.coupons ? response.data.coupons : []);
      setLoading(false);
      setError(null);
    })
    .catch(error => {
      console.error('Erreur lors de la récupération des coupons :', error);
      setCoupons([]);
      setLoading(false);
      setError(error);
    });
  };

  useEffect(() => {
    document.title = "Les coupons de Tomtom ❤️";
    fetchCoupons();
  }, []);

  return (
    <div className='Coupons'>
        {(!logged) && (
          <Link to={{pathname: '/'}} state={{onLoginNavigateTo: location.pathname}}>Connecte toi pour voir tes coupons ❤️</Link>
        )}
        {logged && (
          <>
            <div className="freeCoupons">
              <h1>Coupons disponibles ({coupons.filter(coupon => !coupon.usedDate).length})</h1>
              { adminLogged && (<AddCardCoupon onUpdate={fetchCoupons} />) }
              { (!loading && !error) && coupons.filter(coupon => !coupon.usedDate).map((coupon, id) => <CardCoupon key={id} coupon={coupon} onUpdate={fetchCoupons} />) }
              { (!loading && error) && <>Oups! Il y a un problème pour récupérer tes coupons disponibles 😭</> }
              { loading && <>⏳ 2sec bb je récupère tes coupons sur le web 🕸️🫠</> }
            </div>
            <div className="usedCoupons">
              <h1>Coupons utilisés ({coupons.filter(coupon => coupon.usedDate).length})</h1>
              { (!loading && !error) && coupons.filter(coupon => coupon.usedDate).map((coupon, id) => <CardCoupon key={id} coupon={coupon} onUpdate={fetchCoupons} />) }
              { (!loading && error) && <>Oups! Il y a un problème pour récupérer tes coupons utilisés 😭</> }
              { loading && <>⏳ 2sec bb je récupère tes coupons sur le web 🕸️🫠</> }
            </div>
          </>
        )}
    </div>
  )
}
