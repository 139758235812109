import React, { useRef } from 'react';
import DOMPurify from 'dompurify';
import './AddCardCoupon.scss';
import CreateButton from '../../buttons/create/CreateButton';
import axios from 'axios';

export default function AddCardCoupon({ onUpdate }) {
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);

  const handleAddCoupon = () => {
    const title = DOMPurify.sanitize(titleRef.current.innerText, {USE_PROFILES: {html: false}});
    const description = DOMPurify.sanitize(descriptionRef.current.innerText, {USE_PROFILES: {html: false}});
    
    let api_url = process.env.REACT_APP_API_ADDRESS;
    if (api_url.endsWith("/")) {
        api_url = api_url.slice(0, -1); // Assurez-vous que l'URL est correcte
    }

    axios.post(`${api_url}/api/coupons/coupon`, {
      title: title,
      description: description
      }, {
      withCredentials: true, // Important si votre API utilise des cookies/session pour l'authentification
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD
      },
    })
    .then(response => {
      onUpdate();
    })
    .catch(error => {
      console.error(error);
    });

  };

  return (
    <div className='AddCardCoupon'>
      <div className="content">
        {/* Rendre le h1 éditable et lier à la mise à jour du state */}
        <h1 contentEditable suppressContentEditableWarning={true} ref={titleRef}>Titre du coupon</h1>
        {/* Rendre le p éditable et lier à la mise à jour du state */}
        <p contentEditable suppressContentEditableWarning={true} ref={descriptionRef}>Description du coupon</p>

        <div className="actions">
          <CreateButton confirmModal={true} onSubmit={handleAddCoupon} />
        </div>
      </div>

      <aside>
        <h2>{"Émis le: " + new Date().toLocaleDateString()}</h2>
        <h2>Numéro: xxxxx</h2>
      </aside>
    </div>
  );
}
