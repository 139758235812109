import React, { useRef, useState } from 'react';
import UseButton from '../../buttons/use/UseButton';
import axios from 'axios';
import DeleteButton from '../../buttons/delete/DeleteButton';
import CustomButton from '../../buttons/custom/CustomButton';
import DOMPurify from 'dompurify';
import './CardCoupon.scss';

export default function CardCoupon({ coupon, onUpdate }) {

  const [editableTitle, setEditableTitle] = useState(false);
  const [editableDescription, setEditableDescription] = useState(false);

  const titleRef = useRef(null);
  const descriptionRef = useRef(null);

  const adminLogged = localStorage.getItem('adminLogged');
  let api_url = process.env.REACT_APP_API_ADDRESS;
    if (api_url.endsWith("/")) {
        api_url = api_url.slice(0, -1);
    }

  const formatDate = (date) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  }

  const onSubmit = () => {
    const currentDate = new Date().toISOString();

    axios.put(`${api_url}/api/coupons/coupon`, {
      coupon: {
        title: coupon.title,
        description: coupon.description,
        creationDate: coupon.creationDate,
        usedDate: currentDate,
        _id: coupon._id,
        usedTime: coupon.usedTime + 1
      }
    }, {
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD
      }
    })
    .then(response => {
      console.log(response.data.message);
      onUpdate();
    })
    .catch(error => {
      console.error("Impossible d'utiliser le coupon: " + error);
    })
  };

  const handleDelete = () => {
    axios.delete(`${api_url}/api/coupons/coupon?id=${coupon._id}`, {
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD
      }
    })
    .then(response => onUpdate())
    .catch(error => {
      console.error("Impossible de supprimer le coupon: " + error);
    })
  };

  const handleReGive = () => {
    axios.put(`${api_url}/api/coupons/coupon`, {
      coupon: {
        title: coupon.title,
        description: coupon.description,
        creationDate: coupon.creationDate,
        usedDate: null,
        _id: coupon._id,
        usedTime: coupon.usedTime
      }
    }, {
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD
      }
    })
    .then(response => {
      console.log(response.data.message);
      onUpdate();
    })
    .catch(error => {
      console.error("Impossible de redonner le coupon: " + error);
    })
  }; 

  const handleSetEditableTitle = () => {
    setEditableTitle(!editableTitle);
    console.log(`Title editable = ${editableTitle}`);
  };

  const handleSetEditableDescription = () => {
    setEditableDescription(!editableDescription);
    console.log(`Description editable = ${editableDescription}`);
  };

  const handleUpdateCoupon = () => {
    const title = DOMPurify.sanitize(titleRef.current.innerText, {USE_PROFILES: {html: false}});
    const description = DOMPurify.sanitize(descriptionRef.current.innerText, {USE_PROFILES: {html: false}});
    
    let api_url = process.env.REACT_APP_API_ADDRESS;
    if (api_url.endsWith("/")) {
        api_url = api_url.slice(0, -1);
    }

    axios.put(`${api_url}/api/coupons/coupon`, {
      coupon: {
        title: editableTitle ? title : coupon.title,
        description: editableDescription ? description : coupon.description,
        creationDate: coupon.creationDate,
        usedTime: coupon.usedTime,
        usedDate: coupon.usedDate,
        _id: coupon._id
      }
      }, {
      withCredentials: true,
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD
      },
    })
    .then(response => {
      onUpdate();
    })
    .catch(error => {
      console.error(error);
    });

  };

  return (
    <div className='CardCoupon'>
      
      <div className="content">
        <h1>
          {adminLogged && (
            <CustomButton 
              buttonText={"✎"}
              confirmModal={false}
              onSubmit={handleSetEditableTitle} 
            />
          )} <span 
              contentEditable={editableTitle} 
              suppressContentEditableWarning={true}
              ref={titleRef}
              >{coupon.title}
            </span> 
        </h1>

        <p>
          {adminLogged && (
            <CustomButton 
              buttonText={"✎"}
              confirmModal={false}
              onSubmit={handleSetEditableDescription} 
            />
          )} <span 
              contentEditable={editableDescription}
              suppressContentEditableWarning={true}
              ref={descriptionRef}
              >{coupon.description}
            </span>
        </p>

        <div className="actions">
          { !coupon.usedDate && (<UseButton confirmModal={true} onSubmit={onSubmit} />) }
          { adminLogged && (<DeleteButton confirmModal={true} onSubmit={handleDelete} />) }
          { (adminLogged && coupon.usedDate) && (<CustomButton  buttonText={"♻️ Redonner"} confirmModal={true} onSubmit={handleReGive} />) }
          { (adminLogged && (editableTitle || editableDescription)) && <CustomButton buttonText={"📝 Modifier"} confirmModal={true} onSubmit={handleUpdateCoupon} /> }
        </div>

      </div>

      <aside>
        <h2>{coupon.usedDate ? "Utilisé le: " + formatDate(coupon.usedDate) : "Émis le: " + formatDate(coupon.creationDate)}</h2>
        <h2>Numéro: {coupon._id}</h2>
        <h2>Utilisation: {coupon.usedTime}</h2>
      </aside>
    </div>
  )
}