import React, { useState } from 'react';
import ActionConfirmModal from '../../modal/actionconfirm/ActionConfirmModal';
import './DeleteButton.scss';

export default function DeleteButton({ confirmModal, onSubmit }) {

  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      { openModal && (
        <ActionConfirmModal 
          confirmCallback={() => {
            setOpenModal(false);
            onSubmit();
          }}
          closeCallback={() => setOpenModal(false)}
        />
      ) }
      <button 
        onClick={confirmModal ? () => setOpenModal(true) : () => onSubmit()}
        className='DeleteButton'
      >🗑️ Supprimer
      </button>
    </>
  )
}
