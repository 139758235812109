import React from 'react'
import './ActionConfirmModal.scss'

export default function ActionConfirmModal({ confirmCallback, closeCallback }) {
  return (
    <div className='ActionConfirmModal'>
        <div className="containerModal">
            <div className="actionsButtons">
                <button onClick={() => closeCallback()} className="cancel">Annuler</button>
                <button onClick={() => confirmCallback()} className="confirm">Confirmer</button>
            </div>
        </div>
    </div>
  )
}
