import React, { useEffect, useState } from 'react'
import PasswordInput from '../../components/passwordinput/PasswordInput'
import { useNavigate } from 'react-router-dom';
import './Connexion.scss'

export default function Connexion() {

  const [pass, setPass] = useState("");
  const [error, setError] = useState("");
  const [errorTimeOutId, setErrorTimeOutId] = useState(null);
  const logged = localStorage.getItem('logged');
  const adminLogged = localStorage.getItem('adminLogged');

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Valentine App pour Tomtom ❤️";
    if (logged || adminLogged) navigate("/coupons");
  }, [logged, adminLogged, navigate]);

  const setErrorState = (error) => {
    setError(error);

    let timeOutId = setTimeout(() => {
      if (!error) return;
      setError("")
    }, 3000);

    setErrorTimeOutId(timeOutId);
  };

	const onSubmit = () => {
    if (pass === "2707212007") {
      localStorage.setItem('logged', true);
		  navigate('/coupons')
    } else if (pass === "27072120077913") {
      localStorage.setItem('logged', true);
      localStorage.setItem('adminLogged', true);
		  navigate('/coupons')
    } else {
      setPass("");
      setErrorState("Mauvais mot de passe 🫠");
    }
	};

  if (pass && error) {
    setError("");
    clearTimeout(errorTimeOutId);
    setErrorTimeOutId(null);
  }

  return (
    <div className='Connexion'>
      {!logged && (
        <PasswordInput
          error={error}
          passState={[pass, setPass]} 
          onSubmit={onSubmit} 
        />
      )}
    </div>
  )
}
