import React from 'react'
import './PasswordDisplay.scss'

export default function PasswordDisplay({ password, bgColor, hidePass }) {
    const transformPassword = () => {
        const transformedText = password.replace(/[a-zA-Z0-9]/g, "*");
        return transformedText;
    }
    
    return (
         <div className='PasswordDisplay' style={bgColor ? {background: bgColor} : {}}>
            <h1
                style={!hidePass ? 
                    {color: 'white', fontWeight: 'unset', fontSize: '1.2rem'} :
                    {}}
            >{hidePass ? transformPassword() : password}</h1>
        </div>
      )
}
