import React from 'react'
import PasswordDisplay from '../displays/password/PasswordDisplay'
import NumericPad from '../numericpad/NumericPad'
import './PasswordInput.scss'

export default function PasswordInput({ error, passState:[pass, setPass], onSubmit }) {

  const onPadNumberPressed = (number) => {
    setPass(pass + number);
  };

  const onPadErasePressed = () => {
    setPass(pass.slice(0, -1));
  };
  
  const onPadDeletePressed = () => {
    setPass("");
  }

  return (
    <div className='PasswordInput'>
        <div className="display">
          <PasswordDisplay 
            hidePass={error ? false : true}
            password={error ? 'Mauvais mot de passe 🫠' : pass}
            bgColor={error ? '#dd7973' : null}
          />
        </div>
        <div className="numpad">
          <NumericPad 
            onPadNumberPressed={onPadNumberPressed}
            onPadErasePressed={onPadErasePressed}
            onPadDeletePressed={onPadDeletePressed}
          />
        </div>
        <button
          onClick={(event) => {
            event.preventDefault();
            if (pass) {
              onSubmit();
            }
          }}
          className={pass ? 'submit active' : 'submit deactive'} 
          type="submit"
          >
          Confirmer
        </button>
    </div>
  )
}
