import React from 'react'
import './NumericPad.scss'

export default function NumericPad({ onPadNumberPressed, onPadErasePressed, onPadDeletePressed }) {
  return (
    <div className='NumericPad'>
        <div className='grid'>
            <span onClick={() => onPadNumberPressed('1')}>1</span>
            <span onClick={() => onPadNumberPressed('2')}>2</span>
            <span onClick={() => onPadNumberPressed('3')}>3</span>
            <span onClick={() => onPadNumberPressed('4')}>4</span>
            <span onClick={() => onPadNumberPressed('5')}>5</span>
            <span onClick={() => onPadNumberPressed('6')}>6</span>
            <span onClick={() => onPadNumberPressed('7')}>7</span>
            <span onClick={() => onPadNumberPressed('8')}>8</span>
            <span onClick={() => onPadNumberPressed('9')}>9</span>
            <span 
              onClick={() => onPadDeletePressed()}
              className="delete"
            >␡</span>
            <span 
              onClick={() => onPadNumberPressed('0')} 
              className='zero'
            >0</span>
            <span 
              onClick={() => onPadErasePressed()}
              className="erase"
            >⌫</span>
        </div>
    </div>
  )
}
